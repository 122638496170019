import { Divider, InputNumber } from 'antd';
import Form, { Rule } from 'antd/lib/form';
import { FC } from 'react';
import { FormattedMessage, IntlFormatters, useIntl } from 'react-intl';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import TitleAlt from '../../components/TitleAlt';
import formMessages from '../../i18n/formMessages';

interface SubcontractorWorkforceProps {
    disabled?: boolean;
}
const SubcontractorWorkforce: FC<SubcontractorWorkforceProps> = ({ disabled }) => {
    const { formatMessage } = useIntl();
    const getWorkerFields = (formatMessage: IntlFormatters['formatMessage']) => [
        {
            name: 'totalWorkersCount',
            label: formatMessage({
                id: 'subcontractor_form_drawer.form.item.label.totalWorkersCount',
                defaultMessage: 'Nombre total de travailleurs',
                description: 'input label',
            }),
            tooltip: formatMessage({
                id: 'subcontractor_form_drawer.form.item.tooltip.totalWorkersCount',
                defaultMessage: 'Veuillez communiquer le chiffre à date',
                description: 'tooltip',
            }),
        },
        {
            name: 'permanentWorkersCount',
            label: formatMessage({
                id: 'subcontractor_form_drawer.form.item.label.permanentWorkersCount',
                defaultMessage: 'Nombre total de travailleurs permanents',
                description: 'input label',
            }),
            tooltip: formatMessage({
                id: 'subcontractor_form_drawer.form.item.tooltip.totalWorkersCount',
                defaultMessage: 'Veuillez communiquer le chiffre à date',
                description: 'tooltip',
            }),
        },
        {
            name: 'temporaryWorkersCount',
            label: formatMessage({
                id: 'subcontractor_form_drawer.form.item.label.temporaryWorkersCount',
                defaultMessage: 'Nombre de travailleurs temporaires',
                description: 'input label',
            }),
            tooltip: formatMessage({
                id: 'subcontractor_form_drawer.form.item.tooltip.totalWorkersCount',
                defaultMessage: 'Veuillez communiquer le chiffre à date',
                description: 'tooltip',
            }),
        },
        {
            name: 'fullTimeWorkersCount',
            label: formatMessage({
                id: 'subcontractor_form_drawer.form.item.label.fullTimeWorkersCount',
                defaultMessage: 'Nombre de travailleurs à temps complet',
                description: 'input label',
            }),
            tooltip: formatMessage({
                id: 'subcontractor_form_drawer.form.item.tooltip.totalWorkersCount',
                defaultMessage: 'Veuillez communiquer le chiffre à date',
                description: 'tooltip',
            }),
        },
        {
            name: 'partTimeWorkersCount',
            label: formatMessage({
                id: 'subcontractor_form_drawer.form.item.label.partTimeWorkersCount',
                defaultMessage: 'Nombre tde travailleurs à temps partiel',
                description: 'input label',
            }),
            tooltip: formatMessage({
                id: 'subcontractor_form_drawer.form.item.tooltip.totalWorkersCount',
                defaultMessage: 'Veuillez communiquer le chiffre à date',
                description: 'tooltip',
            }),
        },
        {
            name: 'nationalWorkersCount',
            label: formatMessage({
                id: 'subcontractor_form_drawer.form.item.label.nationalWorkersCount',
                defaultMessage: 'Nombre de travailleurs nationaux',
                description: 'input label',
            }),
            tooltip: formatMessage({
                id: 'subcontractor_form_drawer.form.item.tooltip.totalWorkersCount',
                defaultMessage: 'Veuillez communiquer le chiffre à date',
                description: 'tooltip',
            }),
        },
        {
            name: 'foreignerWorkersCount',
            label: formatMessage({
                id: 'subcontractor_form_drawer.form.item.label.foreignerWorkersCount',
                defaultMessage: 'Nombre de travailleurs étrangers',
                description: 'input label',
            }),
            tooltip: formatMessage({
                id: 'subcontractor_form_drawer.form.item.tooltip.totalWorkersCount',
                defaultMessage: 'Veuillez communiquer le chiffre à date',
                description: 'tooltip',
            }),
        },
    ];
    const validateFields: (field: { name: string; label: string; tooltip: string }) => Rule = (field) => ({
        getFieldValue,
    }) => ({
        async validator() {
            if (
                [
                    'totalWorkersCount',
                    'permanentWorkersCount',
                    'temporaryWorkersCount',
                    'fullTimeWorkersCount',
                    'partTimeWorkersCount',
                    'nationalWorkersCount',
                    'foreignerWorkersCount',
                ].includes(field.name)
            ) {
                const totalWorkersCountValue = getFieldValue('totalWorkersCount')
                    ? Number(getFieldValue('totalWorkersCount'))
                    : undefined;
                const permanentWorkersCountValue = getFieldValue('permanentWorkersCount')
                    ? Number(getFieldValue('permanentWorkersCount'))
                    : undefined;
                const temporaryWorkersCountValue = getFieldValue('temporaryWorkersCount')
                    ? Number(getFieldValue('temporaryWorkersCount'))
                    : undefined;
                const fullTimeWorkersCountValue = getFieldValue('fullTimeWorkersCount')
                    ? Number(getFieldValue('fullTimeWorkersCount'))
                    : undefined;
                const partTimeWorkersCountValue = getFieldValue('partTimeWorkersCount')
                    ? Number(getFieldValue('partTimeWorkersCount'))
                    : undefined;
                const nationalWorkersCountValue = getFieldValue('nationalWorkersCount')
                    ? Number(getFieldValue('nationalWorkersCount'))
                    : undefined;
                const foreignerWorkersCountValue = getFieldValue('foreignerWorkersCount')
                    ? Number(getFieldValue('foreignerWorkersCount'))
                    : undefined;
                const valuesIncoherent =
                    totalWorkersCountValue &&
                    ((permanentWorkersCountValue &&
                        temporaryWorkersCountValue &&
                        permanentWorkersCountValue + temporaryWorkersCountValue !== totalWorkersCountValue) ||
                        (fullTimeWorkersCountValue &&
                            partTimeWorkersCountValue &&
                            fullTimeWorkersCountValue + partTimeWorkersCountValue !== totalWorkersCountValue) ||
                        (nationalWorkersCountValue &&
                            foreignerWorkersCountValue &&
                            nationalWorkersCountValue + foreignerWorkersCountValue !== totalWorkersCountValue));
                if (valuesIncoherent) {
                    return await Promise.reject(formatMessage(formMessages.errorNbWorker));
                } else {
                    return await Promise.resolve();
                }
            }
        },
    });
    return (
        <div>
            <Divider />

            <TitleAlt level={4}>
                <FormattedMessage
                    id="subcontractor_form_drawer.form.item.title.workforce"
                    defaultMessage="Informations main d'oeuvre"
                    description="form section title"
                />
            </TitleAlt>
            {getWorkerFields(formatMessage).map((field) => (
                <Form.Item
                    name={field.name}
                    key={field.name}
                    label={<LabelWithTooltipIcon label={field.label} tooltip={field.tooltip} />}
                    rules={[validateFields(field)]}
                >
                    <InputNumber
                        placeholder={formatMessage({
                            id: 'factoty_form_drawer.form.item.getWorkerFields.input.number',
                            defaultMessage: 'Saisir un nombre',
                            description: 'input placeholder',
                        })}
                        min={0}
                        size="large"
                        style={{ width: '100%' }}
                        disabled={disabled}
                    />
                </Form.Item>
            ))}
        </div>
    );
};

export default SubcontractorWorkforce;
